import { Order } from "../type"
import areaCodes from "./areaCode.json"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
dayjs.extend(utc)
export const relationships = ["-", "Father", "Mother", "Son", "Daughter", "Grandmother", "Grandfather", "Cousin", "Aunt", "Uncle", "Sister", "Brother", "Husband", "Wife", "Grandchild"]
export const religions = ["Budhhist", "Taoism", "Christian", "Free-Thinker", "Roman Catholic", "Straight", "Soka"]
export const races = ["-", "Chinese", "Malay", "Indian", "Others"]
export const initialChecklist = [
  " Embalming: Body preservation",
  "Packing of stocks",
  "Set up funeral wake area",
  "Suppliers’ liaison: Get in touch with supplier for the coordination of the items required for funeral",
  "Overnight vigil service: 1030pm - 7am",
  "CREMATORIUM",
  "Service Hall",
  "Viewing Hall",
  "Reception area",
  "Final resting place : Arrangement for the final resting place",
  "Issuance Of Invoice / Receipt",
  "Follow up Call / WhatsApp Message",
  "Engage for TLL Services",
  "Contact RFM to transfer deceased into our care",
  "Contact RFM to prepare for body presentation",
  "Night service : service team to serve drinks / food / take care of the venue"
]

export const eventsList_1 = [
  { id: "W", type: "Showers of Love" },
  { id: "F", type: "Wake Funeral" },
  { id: "CS", type: "Cremation Service" },
  { id: "AC", type: "Ash collection" },
  { id: "M", type: "Memorial Service" },
  { id: "AS", type: "Angel Star" },
  { id: "", type: "Post Funeral" },
  { id: "BS", type: "Burial Service" },
  { id: "O", type: "Others" }
]

export const eventsList_2 = [
  { id: "W", type: "Showers of Love" },
  { id: "F", type: "Wake Funeral" },
  { id: "CS", type: "Cremation Service" },
  { id: "AC", type: "Ash collection" },
  { id: "M", type: "Memorial Service" },
  { id: "AS", type: "Angel Star" },
  { id: "", type: "Post Funeral" },
  { id: "BS", type: "Burial Service" },
  { id: "O", type: "Others" }
]

export const eventsList_3 = [
  { id: "W", type: "Showers of Love" },
  { id: "F", type: "Wake Funeral" },
  { id: "CS", type: "Cremation Service" },
  { id: "AC", type: "Ash collection" },
  { id: "M", type: "Memorial Service" },
  { id: "AS", type: "Angel Star" },
  { id: "", type: "Post Funeral" },
  { id: "BS", type: "Burial Service" },
  { id: "O", type: "Others" }
]

export const livestreamAction = ["Live Stream Available", "Live Stream Not Available"]

//const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']

export const formatDate = (date: any, format: string, utc: boolean = true) => {
  /*   let selectedDate = date ? date : new Date()
    let strMonth
  
    let month = new Date(selectedDate).getMonth()
    strMonth = months[month]
    
    let year = new Date(selectedDate).getFullYear()
    let currDate = new Date(selectedDate).getDate()
    return `${currDate} ${strMonth} ${year}` */
  let formatedDate = ""
  if (date) {
    formatedDate = dayjs(date).utc(utc).format(format)
  }
  return formatedDate
}

export const debounce = (fn: any) => {
  let timeout: any = null
  return (...args: any) => {
    const next = () => fn(...args)
    clearTimeout(timeout)
    timeout = setTimeout(next, 500)
  }
}

export const validateName = (name: any) => {
  if (name) {
    let regex = /^[a-zA-Z @]{0,50}$/
    return regex.test(name.trim())
  }

  return true
}

export const validateDirectorName = (name: any) => {
  if (name) {
    let regex = /^[a-zA-Z ]{1,20}$/
    return regex.test(name.trim())
  }

  return true
}

export const validatePayNowName = (name: any) => {
  if (name) {
    let regex = /^[A-Za-z0-9_-]*$/
    return regex.test(name.trim())
  }

  return true
}

export const validateTel = (tel: any) => {
  if (tel) {
    let regex = /^(6|8|9)\d{7,11}$/
    return regex.test(tel.trim())
  }

  return true
}

export const validateCountryCode = (tel: any) => {
  if (tel) {
    let regex = /^\d{2,3}$/
    return regex.test(tel.trim())
  }

  return true
}

export const validateEmail = (email: any) => {
  let regex = /^[a-zA-Z0-9]+([._\-+]?[a-zA-Z0-9]+)*@[a-zA-Z0-9]+([.-]?[a-zA-Z0-9]+)*(\.[a-zA-Z0-9]{2,3})+$/
  return regex.test(email.trim())
}

export const validatePw = (pw: string) => {
  if (pw) {
    let regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/
    return regex.test(pw.trim())
  }
  return true
}

export const validateNumeric = (input: string) => {
  const regex = /^[0-9]*$/
  return regex.test(input.trim())
}

export const validatePostal = (postalCode: string) => {
  let areaCode = postalCode.slice(0, 2)
  if (!areaCodes.includes(areaCode)) {
    return false
  }
  let regex = /^\d{6}$/
  return regex.test(postalCode.trim())
}

export const validateVideoUrl = (url: string) => {
  if (url) {
    let regex = /^(ftp|http|https):\/\/[^ "]+$/
    const maxLength = 255

    return regex.test(url) && url.length <= maxLength
  }

  return true
}

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

export function getComparator<Key extends keyof any>(order: Order, orderBy: Key): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy)
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
export function stableSort<T>(array: readonly any[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number])
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0])
    if (order !== 0) {
      return order
    }
    return a[1] - b[1]
  })
  return stabilizedThis.map((el) => el[0])
}

export function csvmaker(data: any[]) {
  // Empty array for storing the values
  let csvRows = []

  // Headers is basically a keys of an
  // object which is id, name, and
  // profession
  const headers = Object.keys(data[0])

  // As for making csv format, headers
  // must be separated by comma and
  // pushing it into array
  csvRows.push(headers.join(","))

  // Pushing Object values into array
  // with comma separation
  for (var row of data) {
    const values = Object.values(row).join(",")
    csvRows.push(values)
  }

  // Returning the array joining with new line
  return csvRows.join("\n")
}

export function downloadCsv(data: any, filename: string) {
  try {
    // Creating a Blob for having a csv file format
    // and passing the data with type
    const blob = new Blob([data], { type: "text/csv" })

    // Creating an object for downloading url
    const url = window.URL.createObjectURL(blob)

    // Creating an anchor(a) tag of HTML
    const a = document.createElement("a")

    // Passing the blob downloading url
    a.setAttribute("href", url)

    // Setting the anchor tag attribute for downloading
    // and passing the download file name
    a.setAttribute("download", filename + ".csv")

    // Performing a download with click
    a.click()

    return { message: "Successfully Downloaded" }
  } catch (e: any) {
    console.log("error-downloadCsv", e.toString())
    return { message: e.data.message }
  }
}
